<template>
    <v-hover v-slot="{ hover }">
        <v-card class="d-flex flex-column fill-height no-hover card-hover" style="min-height: 350px; max-height: 350px;" :flat="flat" @click="isDisplayMoreOpen = true" :ripple="false">
            <div :style="'height: ' +  imageHeight + 'px'">
                <v-carousel v-if="mediaCount > 0" :height="imageHeight" :show-arrows="mediaCount > 1" show-arrows-on-hover hide-delimiters>
                    <v-carousel-item v-for="attachment in getActualityAttachmentYoutubeLinks(actuality)" :key="attachment.id" style="max-height: 300px">
                        <iframe width="100%" :height="imageHeight" :src="getYoutubeEmbedUrl(attachment.targetFileName)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </v-carousel-item>

                    <v-carousel-item v-for="attachment in getActualityAttachmentVideoLinks(actuality)" :key="attachment.id" style="max-height: 300px">
                        <video controls width="100%" :height="imageHeight" :src="attachment.targetFileName"></video>
                    </v-carousel-item>

                    <v-carousel-item v-for="attachment in getActualityAttachmentImages(actuality)" :key="attachment.id" style="max-height: 300px">
                        <v-img :style="{ 'max-height': imageHeight }" :src="getAttachmentUrl(attachment)"></v-img>
                    </v-carousel-item>
                </v-carousel>
            </div>

            <v-toolbar class="flex-grow-0 mt-2" dense flat height="auto">
                <v-spacer />

                <v-toolbar-title class="title pb-0 mb-0 primary--text" :class="{ 'text--lighten-3': hover }">
                    <v-tooltip v-if="actuality.isNew" top>
                        <template v-slot:activator="{ on }">
                            <v-icon class="mr-2" color="yellow" small v-on="on"> fas fa-star </v-icon>
                        </template>
                        Du nouveau ! Publié le  {{ (actuality.startDate ? actuality.startDate : actuality.created) | toDayMonthYearDate() }}
                    </v-tooltip>
                    {{ actuality.title }}
                </v-toolbar-title>

                <v-spacer />
            </v-toolbar>

            <div class="d-flex align-center mr-4 mb-1">
                <v-tooltip v-if="getActualityAttachmentDocuments(actuality).length === 1" :disabled="!getActualityAttachmentDocuments(actuality)[0].title" top>
                    <template v-slot:activator="{ on }">
                        <v-chip @click.stop class="ml-2" color="red" dark label small :href="getAttachmentUrl(getActualityAttachmentDocuments(actuality)[0])" target="_blank" v-on="on">
                            <v-icon x-small left> fas fa-paperclip </v-icon>
                            {{ getActualityAttachmentDocuments(actuality).length }}
                        </v-chip>
                    </template>
                    {{ getActualityAttachmentDocuments(actuality)[0].title }}
                </v-tooltip>
                <v-dialog v-model="isDocumentsDialogOpen" v-if="getActualityAttachmentDocuments(actuality).length > 1" width="40%">
                    <template v-slot:activator="{ on }">
                        <v-chip class="ml-2" color="red" dark label small v-on="on">
                            <v-icon x-small left> fas fa-file </v-icon>
                            {{ getActualityAttachmentDocuments(actuality).length }}
                        </v-chip>
                    </template>

                    <v-card>
                        <v-toolbar dense flat>
                            <v-toolbar-title class="title primary--text">
                                Documents
                            </v-toolbar-title>

                            <v-spacer />

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="isDocumentsDialogOpen = false" color="primary" icon v-on="on">
                                        <v-icon> far fa-times-circle </v-icon>
                                    </v-btn>
                                </template>
                                Fermer la fenêtre
                            </v-tooltip>
                        </v-toolbar>

                        <v-divider />

                        <v-card-text class="pt-4">
                            <v-list >
                                <v-list-item v-for="attachment of getActualityAttachmentDocuments(actuality)" :key="attachment.id" :href="getAttachmentUrl(attachment)" target="_blank">
                                    <v-list-item-action>
                                        <v-icon color="blue"> fas fa-file-pdf </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>
                                        {{ attachment.title }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-spacer />

                <small>
                    {{ (actuality.startDate ? actuality.startDate : actuality.created) | toDayMonthYearDate() }}
                </small>
            </div>

            <v-card-text ref="contentContainer" class="pt-0 flex-grow-1 overflow-hidden ql-editor" style="position: relative;">
                <v-divider />

                <div ref="content" v-html="actuality.content" class="actuality-content pt-2 text--primary" :class="{ 'text--secondary': hover }">

                </div>

                <div v-if="enableDisplayMore" class="d-flex align-end justify-center" style="position: absolute; bottom: 0; left: 0; width: 100%; height: 200px; background-image: linear-gradient(to bottom, transparent, white)">
                    <span class="text-subtitle-1" style="position: absolute; z-index: 2;">
                        
                    </span>
                </div>
            </v-card-text>

            <v-card-actions v-if="allowModification" class="align-end flex-grow-0">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon class="mr-2" :color="getDateInfo('color')" v-on="on">
                            {{ getDateInfo('icon') }}
                        </v-icon>
                    </template>
                    {{ getDateInfo('tooltip') }}
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-if="getActualityAttachmentDocuments(actuality).length > 0" class="mr-2" label small v-on="on">
                            <v-icon x-small left> fas fa-file </v-icon>
                            + {{ getActualityAttachmentDocuments(actuality).length }}
                        </v-chip>
                    </template>
                    Nombre de documents associés
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-if="actuality.context" label small v-on="on">
                            {{ getActualityContext(actuality.context, 'label') }}
                        </v-chip>
                    </template>
                    Contexte
                </v-tooltip>

                <v-spacer />

                <ActualityEditionDialog v-if="isAllowedToUpdateGenerics(actuality.context)" ref="editionDialog" :initActuality="actuality" @updated="$emit('updated')">
                    <template v-slot:activator="{ on: dialog }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn class="mr-2" color="primary" x-small v-on="{ ...dialog, ...tooltip }">
                                    <v-icon x-small> fas fa-edit </v-icon>
                                </v-btn>
                            </template>
                            Modifier l'actualité
                        </v-tooltip>
                    </template>
                </ActualityEditionDialog>

                <ActualityDeletionDialog v-if="isAllowedToUpdateGenerics(actuality.context)" :actuality="actuality" @deleted="$emit('deleted')">
                    <template v-slot:activator="{ on: dialog }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="error" x-small v-on="{ ...dialog, ...tooltip }">
                                    <v-icon x-small> fas fa-trash-alt </v-icon>
                                </v-btn>
                            </template>
                            Supprimer l'actualité
                        </v-tooltip>
                    </template>
                </ActualityDeletionDialog>
            </v-card-actions>

            <v-dialog v-model="isDisplayMoreOpen" width="40%">
                <v-card v-if="isDisplayMoreOpen">
                    <v-carousel hide-delimiters :show-arrows="mediaCount > 1" height="auto" show-arrows-on-hover>
                        <v-carousel-item v-for="attachment in getActualityAttachmentYoutubeLinks(actuality)" :key="attachment.id">
                            <iframe width="100%" height="100%" :src="getYoutubeEmbedUrl(attachment.targetFileName)" title="YouTube video player" frameborder="0" style="min-height: 500px" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </v-carousel-item>
                        <v-carousel-item v-for="attachment in getActualityAttachmentVideoLinks(actuality)" :key="attachment.id">
                            <video controls width="100%" height="100%" :src="attachment.targetFileName"></video>
                        </v-carousel-item>
                        <v-carousel-item v-for="attachment in getActualityAttachmentImages(actuality)" :key="attachment.id" style="text-align: center">
                            <img style="max-width: 100%" :src="getAttachmentUrl(attachment)">
                        </v-carousel-item>
                    </v-carousel>

                    <v-card-text class="pt-4">
                        <h2 class="d-flex align-center">
                            <v-tooltip v-if="actuality.isNew" top>
                                <template v-slot:activator="{ on }">
                                    <v-icon class="mr-2" color="yellow" small v-on="on"> fas fa-star </v-icon>
                                </template>
                                Du nouveau ! Publié le  {{ (actuality.startDate ? actuality.startDate : actuality.created) | toDayMonthYearDate() }}
                            </v-tooltip>
                            {{ actuality.title }} 
                        </h2>

                        <small>
                            {{ (actuality.startDate ? actuality.startDate : actuality.created) | toDayMonthYearDate() }}
                        </small>

                        <v-divider class="my-2" />

                        <div v-html="actuality.content" class="actuality-content ql-editor">

                        </div>

                         <v-list >
                            <v-list-item v-for="attachment of getActualityAttachmentDocuments(actuality)" :key="attachment.id" :href="getAttachmentUrl(attachment)" target="_blank">
                                <v-list-item-action>
                                    <v-icon color="blue"> fas fa-file-pdf </v-icon>
                                </v-list-item-action>
                                <v-list-item-title>
                                    {{ attachment.title }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer />

                        <v-btn @click="isDisplayMoreOpen = false" small> Fermer </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-hover>
</template>

<script>
import actualitiesMixin from '../../mixins/actualities.js';

import ActualityEditionDialog from './ActualityEditionDialog.vue';
import ActualityDeletionDialog from './ActualityDeletionDialog.vue';

export default {
    name: 'ActualityCard',

    mixins: [actualitiesMixin],

    components: {
        ActualityEditionDialog,
        ActualityDeletionDialog
    },

    props: {
        initActuality: { type: Object, required: true },
        allowModification: { type: Boolean, default: false },
        flat: { type: Boolean, default: false },
        imageHeight: { type: String, default: '300px' }
    },

    data: () => ({
        actuality: null,
        enableDisplayMore: false,
        isDocumentsDialogOpen: false,
        isDisplayMoreOpen: false
    }),

    methods: {
        openEditionDialog() {
            this.$refs.editionDialog.open();
        },

        getDateInfo(key) {
            let obj = {};

            if (this.actuality.publish) {
                if (this.actuality.isBeforeDateRange) {
                    obj = { icon: 'fas fa-calendar-alt', color: '', tooltip: 'L\'actualité n\'est pas encore diffusée' };
                } else if (this.actuality.isAfterDateRange) {
                    obj = { icon: 'fas fa-calendar-alt', color: '', tooltip: 'L\'actualité n\'est plus diffusée' };
                } else {
                    obj = { icon: 'fas fa-calendar-check', color: 'green lighten-1', tooltip: 'L\'actualité est actuellement diffusée' };
                }
            } else {
                obj = { icon: 'fas fa-calendar-times', color: '', tooltip: 'L\'actualité n\'est pas activée pour diffusion' };
            }

            return obj[key];
        },

        async fetchActuality() {
            try {
                this.setLoading(true);

                const query = {
                    page: 1,
                    limit: 1,
                    actualities: [this.actuality.id],
                    include: 'attachments,targetCodes'
                };
                const { actualities, err } = await this.repos.actualities.getActualities(query);
                if (err) {
                    throw new Error(err);
                } else {
                    this.actuality = actualities[0];
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement de l\'actualité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        computeDisplayMore() {
            if (this.$refs.contentContainer && this.$refs.content) {
                const container = this.$refs.contentContainer.getBoundingClientRect();
                const content = this.$refs.content.getBoundingClientRect();
                this.enableDisplayMore = content.height > container.height;
                if (!this.enableDisplayMore) {
                    this.enableDisplayMore = this.mediaCount > 0;
                }
            }
        }
    },

    computed: {
        mediaCount() {
            return this.getActualityAttachmentImages(this.actuality).length +
                this.getActualityAttachmentYoutubeLinks(this.actuality).length +
                this.getActualityAttachmentVideoLinks(this.actuality).length;
        }
    },

    watch: {
        initActuality() {
            this.actuality = this.initActuality;
        }
    },

    created() {
        this.actuality = this.initActuality;
        this.$nextTick(() => {
            this.computeDisplayMore();
        });
    }
};
</script>

<style lang="scss" scoped>
.sidebar-box {
    position: relative;
    overflow: hidden;

    .read-more {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 30px 0;

        background-image: linear-gradient(to bottom, transparent, white);
    }
}

.actuality-title {
    color: #506fca;
}

.actuality-content::v-deep {
    p {
        margin-bottom: 0;
    }
}

.no-hover::before {
    display: none;
}
</style>